// Import GenericTasks from "../general/GenericTasks";

// const buttons = [
//     {
//       to: "warehouse-transfers",
//       title: "Warehouse Transfers"
//     },
//     {
//       to: "hauling/mrp-transport",
//       title: "MRP Transport"
//     }
//   ];

export default function Hauling (): JSX.Element {
  // Return <GenericTasks heading="Hauling Tasks" buttons={buttons}/>
  return <h2>Coming Soon...</h2>
}
